<template>
  <div style="overflow: auto; height: 80vh; width: 65vw">
    <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/profile/third_party' }">{{
        $t("personal.thirdPartyManagement.breadcrumb.app")
      }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{
        $t("personal.thirdPartyManagement.breadcrumb.settings")
      }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div
      v-for="(item, index) in obtained_list"
      :key="item.applicationId"
      draggable
      style="margin: 30px"
      @dragstart="dragging(index)"
      @dragover="dragover(index)"
      @dragend="dragend"
    >
      <el-card class="card">
        <el-row type="flex" :gutter="20" class="card-item">
          <i class="iconfont icon-drag" style="font-size: 20px" />
          <el-col class="card-avatar" :span="3">
            <el-avatar
              style="background: transparent"
              shape="square"
              :size="40"
              :src="item.avatar"
            ></el-avatar>
          </el-col>

          <el-col class="card-name" :span="8"> {{ item.name }} </el-col>

          <el-col class="card-btn" :span="2" :offset="14"> </el-col>
          <el-col class="card-btn" :span="2">
            <el-button
              round
              size="mini"
              style="background: rgba(255, 87, 51, 0.15); font-size: 12px"
              @click="deleteItem(item, index)"
            >
              {{
                $t("personal.thirdPartyManagement.settings.removeBtn")
              }}</el-button
            >
          </el-col>
        </el-row>
      </el-card>
    </div>
    <el-dialog
      style="text-align: left"
      :title="$t('personal.thirdPartyManagement.settings.confirmForm.title')"
      width="25%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <i class="el-icon-warning" style="color: rgba(230, 162, 60, 1)"></i
      >&nbsp;&nbsp;<span>{{
        $t("personal.thirdPartyManagement.settings.confirmForm.desc")
      }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="delete_app_by_id(item, index)">{{
          $t("btn.deleteBtn")
        }}</el-button>
        <el-button @click="dialogVisible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { delete_app_by_id, get_sorted_list } from "@/network/third-party/index";
export default {
  data() {
    return {
      dialogVisible: false,
      curItem: {},
      curIndex: 0,
      from_node_index: 0, // 拖拽起
      to_node_index: 0, // 拖拽止
    };
  },
  computed: {
    obtained_list() {
      return this.$store.getters.project_third_apps;
    },
  },
  methods: {
    //拖拽
    dragging(index) {
      this.from_node_index = index;
    },
    dragover(index) {
      this.to_node_index = index;
    },
    dragend() {
      const origin_arr = [...this.obtained_list];
      const new_arr = [...this.obtained_list];
      new_arr.splice(
        this.from_node_index,
        1,
        ...new_arr.splice(this.to_node_index, 1, new_arr[this.from_node_index])
      );

      this.$store.commit("SET_THIRD_APPS", new_arr);
      const ids = new_arr.map((item) => item.applicationId);
      get_sorted_list(ids)
        .then((res) => {})
        .catch((e) => {
          this.$store.commit("SET_THIRD_APPS", origin_arr);
        });
    },
    // 打开dialog
    deleteItem(item, index) {
      this.dialogVisible = true;
      this.curItem = item;
      this.curIndex = index;
    },
    // 删除app
    delete_app_by_id() {
      this.dialogVisible = false;
      let arr = [...this.obtained_list];
      arr.splice(this.curIndex, 1);
      delete_app_by_id(this.curItem.applicationId)
        .then((res) => {
          this.$store.commit("SET_THIRD_APPS", arr);
          this.$message({
            type: "success",
            message: this.$t("baseline.message.deleteSuccess"),
          });
        })
        .catch((e) => {
          arr.splice(this.curIndex, 0, this.curItem);
          this.$store.commit("SET_THIRD_APPS", arr);
          console.log(e);
        });
    },
    // 跳转到配置界面
    go_to_config(event, item) {
      this.$router.push({
        name: "third_party_detail",
        params: { applicationId: item.applicationId, name: item.name },
      });
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  margin-bottom: 2vh;
  font-size: 18px;
}
.card {
  // margin: 30px;
  border-radius: 18px;
  &-item {
    align-items: center;
  }
  &-name {
    font-size: 16px;
    text-align: left;
  }
}
</style>
